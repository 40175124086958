import React from "react"
import styled from "styled-components"
import Paragraph from "../Commons/Paragraph"
import { Row, Col } from "react-awesome-styled-grid"
import Title from "../Commons/Title"

const ModuleWrapper = styled.div`
  padding: 24px 24px 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 28px 0px 60px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 48px 24px 90px;
  }
`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 60px;
    align-items: baseline;
  }
`

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;
  max-width: 272px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    max-width: 420px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
    max-width: 710px;
    text-align: left;
  }
`

const ParagraphWrapper = styled.div`
  text-align: center;
  max-width: 300px;
  margin-bottom: 24px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin-bottom: 40px;
    max-width: 380px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 501px;
    text-align: left;
  }
`

const MainImageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  margin-bottom: 24px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    height: 300px;
    margin-bottom: 0px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    max-width: 100%;
    margin-right: 0px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 100%;
  }

  @media (min-width: 1924px) {
    max-width: 466px;
  }
`

const MainImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
`

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 27px;
  column-gap: 27px;
  width: fit-content;
  align-items: flex-end;

  & > img {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    border-radius: 24px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    & > img {
      &:first-child {
        grid-column-start: auto;
        grid-column-end: auto;
      }
      border-radius: 24px;
    }
    row-gap: 0px;
    column-gap: 36px;
    grid-template-columns: 5fr 2fr 2fr;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    grid-template-columns: 1fr 1fr;
    row-gap: 27px;

    & > img {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      border-radius: 24px;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    column-gap: 48px;
    row-gap: 0;
    grid-template-columns: 5fr 2fr 2fr;

    & > img {
      &:first-child {
        grid-column-start: auto;
        grid-column-end: auto;
      }
      border-radius: 24px;
    }
  }

  @media (min-width: 1924px) {
    column-gap: 64px;
    grid-template-columns: auto auto auto;
  }
`

type ImageProps = {
  max_Width: number
}

const Image = styled.img<ImageProps>`
  width: 100%;
  height: auto;
  object-fit: cover;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: ${props => props.max_Width}px;
  }
`

const ColorBar = styled.div`
  position: absolute;
  left: 0;
  display: grid;
  grid-template-rows: repeat(5, 1fr);

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    top: 40px;
    height: auto;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    top: 120px;
    height: auto;
  }
  @media (min-width: 1924px) {
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
  }
`
type BarItemProps = {
  variable?: string
  color: string
}

const BarItem = styled.div<BarItemProps>`
  width: 16px;
  height: 47px;
  background-color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.primary.black};

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    width: 22px;
    height: 68px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: 28px;
    height: 110px;
  }
  @media (min-width: 1924px) {
    width: 40px;
    height: 100%;
  }
`
type Props = {
  title: string
  description: string
  image: string
  image_1: string
  image_2: string
  image_3: string
}

const AboutHero: React.FC<Props> = ({
  title,
  description,
  image,
  image_1,
  image_2,
  image_3,
}) => {
  return (
    <>
      <ModuleWrapper>
        <ColorBar>
          <BarItem variable="secondary" color="yellow" />
          <BarItem variable="secondary" color="orange" />
          <BarItem variable="secondary" color="blue" />
          <BarItem variable="secondary" color="pink" />
          <BarItem variable="secondary" color="purple" />
        </ColorBar>
        <Row>
          <Col xs={12} md={3} lg={5}>
            <MainImageWrapper>
              <MainImage src={image} draggable="false" />
            </MainImageWrapper>
          </Col>
          <Col xs={12} md={5} lg={7}>
            <FlexWrapper>
              <TitleWrapper>
                <Title level={1} variable="primary" color="black" uppercase>
                  {title}
                </Title>
              </TitleWrapper>
              <ParagraphWrapper>
                <Paragraph
                  level={1}
                  variable="primary"
                  color="black"
                  weight="500"
                >
                  {description}
                </Paragraph>
              </ParagraphWrapper>
            </FlexWrapper>

            <ImagesWrapper>
              <Image src={image_1} max_Width={590} draggable="false" />
              <Image src={image_2} max_Width={345} draggable="false" />
              <Image src={image_3} max_Width={227} draggable="false" />
            </ImagesWrapper>
          </Col>
        </Row>
      </ModuleWrapper>
    </>
  )
}

export default AboutHero
