// If you don't want to use TypeScript you can delete this file!
import React, { useEffect } from "react"
import { PageProps, graphql } from "gatsby"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MediaInquiries from "../components/MediaInquiries"
import WhatWeBuild from "../components/WhatWeBuild"
import GameSlider from "../components/GameSlider"
import OurPassion from "../components/OurPassion"
import AboutHero from "../components/AboutHero"

import MediaInquiriesImage from "../images/AboutPage/media-inquries.png"
import WhatWeBuildImage from "../images/AboutPage/whatwebuild.png"
import OurPassionImage from "../images/AboutPage/our_passion.png"
import slider_1 from "../images/AboutPage/game-slider-1.png"
import slider_2 from "../images/AboutPage/game-slider-2.png"
import slider_3 from "../images/AboutPage/game-slider-3.png"
import slider_4 from "../images/AboutPage/game-slider-4.png"
import slider_5 from "../images/AboutPage/game-slider-5.png"
import slider_6 from "../images/AboutPage/game-slider-6.png"
import slider_7 from "../images/AboutPage/game-slider-7.png"
import slider_8 from "../images/AboutPage/game-slider-8.png"

import OurPassionImage_1 from "../images/AboutPage/our_passion-1.png"
import OurPassionImage_2 from "../images/AboutPage/our_passion-2.png"
import OurPassionImage_3 from "../images/AboutPage/our_passion-3.png"
import OurPassionImage_4 from "../images/AboutPage/our_passion-4.png"

import AboutHeroImage from "../images/AboutPage/about_commander.png"
import AboutHeroImage_1 from "../images/AboutPage/about_hero-1.png"
import AboutHeroImage_2 from "../images/AboutPage/about_hero-2.png"
import AboutHeroImage_3 from "../images/AboutPage/about_hero-3.png"

const AboutPage: React.FC<PageProps> = () => {
  const { t } = useTranslation()

  useEffect(() => {
    if (window !== undefined) {
      require("lazysizes/plugins/attrchange/ls.attrchange.js")
      require("lazysizes/plugins/bgset/ls.bgset")
      require("lazysizes")
    }
  }, [])

  return (
    <Layout>
      <Seo title="Choice Provisions | About" description="Choice Provisions was founded in 2007, making us about 417 in game industry years. We've taken the wisdom we've accrued over time and focused it towards developing the best games we can in the most sustainable way possible." />
      <AboutHero
        title="Sometimes a really good video game is just awesome."
        description="Choice Provisions was founded by Mike Roush and Alex Neuse in 2007. Since then, we've been bringing our unique vibrations to gamers worldwide on as many platforms as possible."
        image={AboutHeroImage}
        image_1={AboutHeroImage_1}
        image_2={AboutHeroImage_2}
        image_3={AboutHeroImage_3}
      />
      <OurPassion
        image={OurPassionImage}
        title="Our Enthusiasm"
        description="At Choice Provisions, we strive to make games that we ourselves enjoy. With any luck, several other human beings will enjoy them as well!"
        image_1={OurPassionImage_1}
        image_2={OurPassionImage_2}
        image_3={OurPassionImage_3}
        image_4={OurPassionImage_4}
      />
      <GameSlider
        title="how we are different"
        cards={[
          {
            image: slider_1,
            title: "Wacky",
            color: "orange",
          },
          {
            image: slider_2,
            title: "Fun",
            color: "blue",
          },
          {
            image: slider_3,
            title: "Oddball",
            color: "pink",
          },
          {
            image: slider_4,
            title: "Vibrant",
            color: "purple",
          },
          {
            image: slider_5,
            title: "Trippy",
            color: "orange",
          },
          {
            image: slider_6,
            title: "Pears",
            color: "blue",
          },
          {
            image: slider_7,
            title: "Unexpected",
            color: "pink",
          },
          {
            image: slider_8,
            title: "Inspired",
            color: "purple",
          },
        ]}
      />
      <WhatWeBuild
        image={WhatWeBuildImage}
        title="what we build"
        description="Choice Provisions was founded in 2007, making us about 417 in game industry years. We've taken the wisdom we've accrued over time and focused it towards developing the best games we can in the most sustainable way possible."
      />
      <MediaInquiries
        logo={MediaInquiriesImage}
        title="media inquiries"
        description="Press? Streamer? Water buffalo? Get in touch!"
        button_text="contact us"
      />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
