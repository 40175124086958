import React from "react"
import styled from "styled-components"
import _ from "lodash"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay } from "swiper/core"
import Subtitle from "../Commons/Subtitle"
import Paragraph from "../Commons/Paragraph"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Title from "../Commons/Title"

import PrevButton from "../../images/AboutPage/slider-prev.png"
import NextButton from "../../images/AboutPage/slider-next.png"
import Ufo from "../../images/AboutPage/slider-ufo.png"
import SliderCard from "../Cards/SliderCards"
import { moveHandler } from "../../functions/eyeTracker"

SwiperCore.use([Navigation, Autoplay])

const ModuleWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 68px 0px 61px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 84px 0px 123px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    padding: 90px 0px 160px;
  }
`
const HalfContainer = styled.div`
  margin-left: auto;
  max-width: 100%;
  box-sizing: border-box;
  padding-left: 1.5rem;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xs}rem) {
    width: calc(46rem + (100vw - 46rem) / 2);
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    width: calc(46rem + (100vw - 46rem) / 2);
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    width: calc(60rem + (100vw - 60rem) / 2);
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: calc(76.875rem + (100vw - 76.875rem) / 2);
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    width: calc(76.875rem + (100vw - 76.875rem) / 2);
  }

  .swiper-container {
    padding-top: 200px;
    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
      padding-top: 153px;
    }
    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
      overflow: visible;
    }
    @media (min-width: 1640px) {
      overflow: hidden;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 80px;
    width: 80px;
    height: 80px;
    margin-top: 0px;
    background-size: cover;

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
      top: 0px;
      width: 96px;
      height: 96px;
    }

    &:after {
      content: none;
    }
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(${PrevButton});
    left: 50%;
    right: auto;
    transform: translateX(calc(-50% - 70px));

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
      left: 0px;
      right: auto;
      transform: none;
    }
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(${NextButton});
    left: auto;
    right: 50%;
    transform: translateX(calc(50% + 46px));

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
      left: 120px;
      right: auto;
      transform: none;
    }
  }
  .swiper-slide {
    width: auto;
  }
`

const TitleWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 350px;
  text-align: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    left: 245px;
    transform: none;
    max-width: 320px;
    text-align: left;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    left: 285px;
    max-width: 460px;
  }
`
const SideImageWrapper = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    position: absolute;
    top: 0px;
    bottom: 0px;

    display: flex;
    align-items: baseline;
    justify-content: center;

    left: auto;
    right: 64px;
    width: 260px;
  }
  @media (min-width: 1640px) {
    right: auto;
    width: calc(100vw - (76.875rem + (100vw - 76.875rem) / 2));
    left: 0px;
  }
`

type EyeProps = {
  x: any
  y: any
}

const SideImagEye = styled.div<EyeProps>`
  margin-top: 180px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 4%;
    height: 6%;
    background: #666666;
    top: 32%;
    left: 48%;
    z-index: 12;
    transition: transform 0.3s linear;
    transform: matrix(1, 0, 0, 1, ${props => props.x}, ${props => props.y});
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 16px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 32px;
  }
  @media (min-width: 1640px) {
    margin-top: 180px;
  }
`

const SideImage = styled.img`
  max-width: 469px;
  width: 100%;
`

type Props = {
  title: string
  cards: Array<{
    image: string
    title: string
    color: string
  }>
}

const GameSlider: React.FC<Props> = ({ title, cards }) => {
  const [xValue, setX] = React.useState(0)
  const [yValue, setY] = React.useState(0)
  const EyeRef = React.useRef(null)

  const handleMouseMove = (e: any) => {
    setX(moveHandler(EyeRef, 29, e).x)
    setY(moveHandler(EyeRef, 29, e).y)
  }

  React.useEffect(() => {
    //  + TODO: header dan page degistirdiginde remove listener calismiyor ve hata alcaksin.
    window.addEventListener("mousemove", _.throttle(handleMouseMove, 300))
    return () =>
      window.removeEventListener("mousemove", _.throttle(handleMouseMove, 300))
  }, [])

  return (
    <>
      <ModuleWrapper>
        <SideImageWrapper className="slide_top_animation">
          <SideImagEye ref={EyeRef} x={xValue} y={yValue}>
            <SideImage src={Ufo} draggable="false" />
          </SideImagEye>
        </SideImageWrapper>
        <HalfContainer>
          <Swiper
            autoplay={{ delay: 800, pauseOnMouseEnter: true }}
            spaceBetween={24}
            slidesPerView={"auto"}
            navigation
            loop={true}
          >
            <TitleWrapper>
              <Title level={1} variable="primary" color="black" uppercase>
                {title}
              </Title>
            </TitleWrapper>
            {cards.map((card, index) => (
              <SwiperSlide key={index}>
                <SliderCard
                  image={card.image}
                  title={card.title}
                  color={card.color}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </HalfContainer>
      </ModuleWrapper>
    </>
  )
}

export default GameSlider
