export const moveHandler = (EyeRef: any, offsetTop: number, e: any) => {
  if (
    EyeRef === undefined ||
    EyeRef === null ||
    EyeRef.current === undefined ||
    EyeRef.current === null
  ) {
    return {
      x: 0,
      y: 0,
    }
  }

  const lx =
    EyeRef?.current.getBoundingClientRect().left +
    EyeRef?.current.clientWidth / 2
  const ly =
    EyeRef?.current.getBoundingClientRect().top +
    (EyeRef?.current.clientHeight * offsetTop) / 100

  const pX = lx - e.pageX
  const pY = ly - e.clientY

  return {
    x: Math.min(Math.max(parseInt(pX), -8), 8) * -1,
    y: Math.min(Math.max(parseInt(pY), -8), 8) * -1,
  }
} // moveHandler
