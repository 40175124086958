import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-awesome-styled-grid"
import Title from "../Commons/Title"
import Paragraph from "../Commons/Paragraph"

const ModuleWrapper = styled.div`
  background-color: ${props => props.theme.variables.secondary.yellow};
  margin-top: 56px;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 112px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 80px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: cover;

  max-width: 206px;
  top: -56px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 411px;
    top: -112px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 564px;
    top: -80px;
    right: 64px;
    left: initial;
    transform: initial;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 40px;
    text-align: left;
  }
`

const ParagraphWrapper = styled.div`
  text-align: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    text-align: left;
  }
`

const TextWrapper = styled.div`
  padding: 140px 0px 72px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 280px 0px 82px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 134px 0px 166px;
  }
`

type Props = {
  image: string
  title: string
  description: string
}

const WhatWeBuild: React.FC<Props> = ({ image, title, description }) => {
  return (
    <>
      <ModuleWrapper>
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <ImageWrapper>
                <Image src={image} draggable="false" />
              </ImageWrapper>
            </Col>
            <Col md={12} lg={6}>
              <TextWrapper>
                <TitleWrapper>
                  <Title level={1} variable="primary" color="black" uppercase>
                    {title}
                  </Title>
                </TitleWrapper>
                <ParagraphWrapper>
                  <Paragraph
                    level={1}
                    variable="primary"
                    color="black"
                    weight="500"
                  >
                    {description}
                  </Paragraph>
                </ParagraphWrapper>
              </TextWrapper>
            </Col>
          </Row>
        </Container>
      </ModuleWrapper>
    </>
  )
}

export default WhatWeBuild
