import React from "react"
import styled from "styled-components"
import _ from "lodash"
import { Col, Container, Row } from "react-awesome-styled-grid"
import Title from "../Commons/Title"
import Paragraph from "../Commons/Paragraph"
import { moveHandler } from "../../functions/eyeTracker"

import Ufo from "../../images/AboutPage/slider-ufo-w-eye.png"
import OurPassion_4 from "../../images/AboutPage/our_passion-4-w-eye.png"

const ModuleWrapper = styled.div`
  position: relative;
  margin-top: 32px;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 60px;
    margin-bottom: 90px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 80px;
    margin-bottom: 90px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: block;
    padding-right: 70px;
    padding-left: 70px;
  }
  @media (min-width: 1924px) {
    padding-left: 0px;
  }
`

const MainImage = styled.img`
  max-width: 272px;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    max-width: 360px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 458px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 564px;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 40px;
    text-align: left;
  }
`

const ParagraphWrapper = styled.div`
  text-align: center;
  max-width: 320px;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    max-width: 370px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 465px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    text-align: left;
    max-width: none;
    margin: 0;
  }
`

const TextWrapper = styled.div`
  margin-top: 24px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 68px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 40px;
    margin-left: 70px;
  }
`

const RightImageWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 77px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-left: 0px;
    margin-right: 0;
    margin-top: 58px;
  }
  @media (min-width: 1924px) {
    margin-left: 70px;
  }
`
const Image1 = styled.img`
  max-width: 62px;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 80px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 100px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 140px;
    margin-top: 100px;
  }
  @media (min-width: 1924px) {
    max-width: 170px;
  }
`

const Image2 = styled.img`
  max-width: 120px;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 140px;
    margin-right: 0px;

    margin-bottom: auto;
  }
  @media (min-width: 1924px) {
    max-width: 170px;
    margin-right: -100px;
  }
`

const LeftImageWRapper = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 50px;
  justify-content: flex-end;

  display: none;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    display: flex;
    width: auto;
  }

  @media (min-width: 1640px) {
    left: 46px;
  }

  @media (min-width: 1924px) {
    width: calc((100vw - 76.875rem) / 2);
  }
`
const Image3 = styled.img`
  max-width: 120px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: auto;
  @media (min-width: 1924px) {
    max-width: 180px;
  }
`

const Image4 = styled.img`
  max-width: 120px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: auto;
  margin-bottom: 40px;
  @media (min-width: 1924px) {
    max-width: 180px;
  }
`

const DesktopImageWrapper = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const TabletImageWrapper = styled.div`
  width: 100%;

  & > div:first-child {
    img:nth-child(1) {
      margin-left: 30px;
      margin-top: 30px;
    }
    img:nth-child(2) {
      margin-top: 50px;
    }
    img:nth-child(3) {
      margin-top: -20px;
      margin-bottom: auto;
    }
  }

  & > div:last-child {
    img:first-child {
      max-width: 160px;
      margin-top: 30px;

      @media (min-width: ${props =>
          props.theme.awesomegrid.breakpoints.md}rem) {
        max-width: 252px;
        margin-top: 0px;
      }
    }
    img:last-child {
      margin-top: -20px;
      margin-bottom: auto;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: none;
  }
`

const TabletRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 0px 20px;
  }
`

type EyeProps = {
  x: any
  y: any
}

const SideImagEye = styled.div<EyeProps>`
  position: relative;
  margin-bottom: auto;

  &:before {
    content: "";
    position: absolute;
    width: 12%;
    height: 10%;
    background: #666666;
    top: 40%;
    left: 42%;
    z-index: 12;
    transition: transform 0.3s linear;
    transform: matrix(1, 0, 0, 1, ${props => props.x}, ${props => props.y});
  }
`

type Props = {
  image: string
  title: string
  description: string
  image_1: string
  image_2: string
  image_3: string
  image_4: string
}

const OurPassion: React.FC<Props> = ({
  image,
  title,
  description,
  image_1,
  image_2,
  image_3,
  image_4,
}) => {
  const [xValue, setX] = React.useState(0)
  const [yValue, setY] = React.useState(0)
  const EyeRef = React.useRef(null)

  const handleMouseMove = (e: any) => {
    setX(moveHandler(EyeRef, 35, e).x)
    setY(moveHandler(EyeRef, 35, e).y)
  }

  React.useEffect(() => {
    // + TODO: header dan page degistirdiginde remove listener calismiyor ve hata alcaksin.
    window.addEventListener("mousemove", _.throttle(handleMouseMove, 300))
    return () =>
      window.removeEventListener("mousemove", _.throttle(handleMouseMove, 300))
  }, [])
  return (
    <>
      <ModuleWrapper>
        <LeftImageWRapper>
          <SideImagEye
            className="slide_top_animation"
            ref={EyeRef}
            x={xValue}
            y={yValue}
          >
            <Image3 src={image_4} draggable="false" />
          </SideImagEye>

          <Image4
            src={image_3}
            className="slide_top_animation"
            draggable="false"
          />
        </LeftImageWRapper>
        <Container>
          <Row>
            <Col sm={12} md={4} lg={6}>
              <ImageWrapper>
                <MainImage src={image} draggable="false" />
              </ImageWrapper>
            </Col>
            <Col sm={12} md={4} lg={6}>
              <TextWrapper>
                <TitleWrapper>
                  <Title level={1} variable="primary" color="black" uppercase>
                    {title}
                  </Title>
                </TitleWrapper>
                <ParagraphWrapper>
                  <Paragraph
                    level={1}
                    variable="primary"
                    color="black"
                    weight="500"
                  >
                    {description}
                  </Paragraph>
                </ParagraphWrapper>
              </TextWrapper>
              <RightImageWrapper>
                <DesktopImageWrapper>
                  <Image1
                    src={image_1}
                    className="breathe_animation"
                    draggable="false"
                  />
                  <Image2
                    src={image_2}
                    className="slide_top_animation"
                    draggable="false"
                  />
                </DesktopImageWrapper>
                <TabletImageWrapper>
                  <TabletRow>
                    <Image1
                      src={image_1}
                      className="breathe_animation"
                      draggable="false"
                    />
                    <Image1
                      src={image_2}
                      className="slide_top_animation"
                      draggable="false"
                    />
                    <Image1
                      src={image_3}
                      className="breathe_animation"
                      draggable="false"
                    />
                  </TabletRow>
                  <TabletRow>
                    <Image1
                      src={Ufo}
                      className="slide_top_animation"
                      draggable="false"
                    />
                    <Image1
                      src={OurPassion_4}
                      className="slide_top_animation"
                      draggable="false"
                    />
                  </TabletRow>
                </TabletImageWrapper>
              </RightImageWrapper>
            </Col>
          </Row>
        </Container>
      </ModuleWrapper>
    </>
  )
}

export default OurPassion
