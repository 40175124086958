import React from "react"
import styled from "styled-components"
import { Container } from "react-awesome-styled-grid"
import Title from "../Commons/Title"
import Paragraph from "../Commons/Paragraph"
import Button from "../Commons/Button"

const ModuleWrapper = styled.div`
  padding: 48px 0px 78px;
  margin-top: -1px;
  background-color: ${props => props.theme.variables.secondary.purple};

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 169px 0px 214px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 80px 0px 142px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    padding: 136px 0px 263px;
  }
`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const Image = styled.img`
  margin-bottom: 24px;
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 320px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
    max-width: 432px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    margin-bottom: 103px;
    max-width: 605px;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
  max-width: 280px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
    max-width: 460px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: none;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    margin-bottom: 143px;
    max-width: none;
  }
`

const ParagraphWrapper = styled.div`
  margin-bottom: 48px;
  text-align: center;
  max-width: 320px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
    max-width: 460px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    display: none;
  }
`

const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
`

const HeadColors = styled.div``

type HeadItemProps = {
  variable?: string
  color: string
}

const HeadItem = styled.div<HeadItemProps>`
  height: 15px;
  background-color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.primary.black};
`

type Props = {
  logo: string
  title: string
  description: string
  button_text: string
}

const MediaInquiries: React.FC<Props> = ({
  logo,
  title,
  description,
  button_text,
}) => {


  return (
    <>
      <HeadColors>
        <HeadItem variable="secondary" color="orange" />
        <HeadItem variable="secondary" color="blue" />
        <HeadItem variable="secondary" color="pink" />
        <HeadItem variable="secondary" color="purple" />
      </HeadColors>
      <ModuleWrapper>
        <Container>
          <FlexWrapper>
            <Image src={logo} draggable="false" />
            <TitleWrapper>
              <Title level={1} variable="primary" color="white" uppercase>
                {title}
              </Title>
            </TitleWrapper>
            <ParagraphWrapper>
              <Paragraph
                level={1}
                variable="primary"
                color="white"
                weight="500"
              >
                {description}
              </Paragraph>
            </ParagraphWrapper>
            <Button type="primary">
              <StyledA href="mailto:pr@totallychoice.com" target="_blank">
                {button_text}
              </StyledA>
            </Button>
          </FlexWrapper>
        </Container>
      </ModuleWrapper>
    </>
  )
}

export default MediaInquiries
