import React from "react"
import styled from "styled-components"
import Title from "../../Commons/Title"

type TitleProps = {
  color: string
}

const CardContainer = styled.div<TitleProps>`
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    border-width: 8px;
    border-style: solid;
    border-color: ${props =>
      props.theme.variables["secondary"][props?.color]
        ? props.theme.variables["secondary"][props?.color]
        : props.theme.variables.secondary.yellow};
  }
  width: 222px;
  height: 231px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    width: 497px;
    height: 509px;

    &:before {
      border-width: 16px;
    }
  }
`

const CardImage = styled.img`
  border-radius: 24px;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
`

const TitleWrapper = styled.div<TitleProps>`
  position: absolute;
  z-index: 2;
  bottom: 8px;
  left: 0px;
  max-width: 180px;
  width: 100%;
  padding: 8px 32px;
  border-radius: 0px 24px;
  background-color: ${props =>
    props.theme.variables["secondary"][props?.color]
      ? props.theme.variables["secondary"][props?.color]
      : props.theme.variables.secondary.yellow};

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 306px;
    padding: 24px 48px;
  }
`

export type CardsProps = {
  image: string
  title: string
  color: string
}

const SliderCard: React.FC<CardsProps> = ({ image, title, color }) => {
  return (
    <>
      <CardContainer color={color}>
        <CardImage src={image} draggable="false" />
        <TitleWrapper color={color}>
          <Title level={2} variable="primary" color="white">
            {title}
          </Title>
        </TitleWrapper>
      </CardContainer>
    </>
  )
}

export default SliderCard
